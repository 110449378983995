import * as HttpStatus from 'http-status-codes';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { initializeClass } from '@utils/utils';
import { ToastrService } from 'ngx-toastr';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { ShowFullRegisterFormService } from '@modules/register/show-full-register-form.service';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { BEInstallationsService } from '@services/backend-services/installations/be-installations.service';
import { RegisterPlusActionTokenDataEntity } from '@entities/action-token-data.entity';
import { BaseRegisterComponent } from '../components/base-register.component';
import { BEDesktopService } from '@services/backend-services/desktop/be-desktop.service';
import { first } from 'rxjs/operators';
import { RegisterOfflinePlusEntity } from '@entities/register-offline-plus.entity';
import { RegisterEntryForms } from '../components/classes/register-entry-forms';
import { RestartLoginFormService } from '@shared/components/login-form/restart-login-form.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-register-qr-code',
  templateUrl: './register-qr-code.component.html',
  styleUrls: ['./register-qr-code.component.scss'],
})
export class RegisterQRCodeComponent extends BaseRegisterComponent implements OnInit {
  private checkSum: string;
  private registerOfflinePlusData: RegisterOfflinePlusEntity;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public translationMWService: TranslationMWService,
    public localStorageService: LocalStorageService,
    public beInstallationsService: BEInstallationsService,
    public beDesktopService: BEDesktopService,
    public restartLoginFormService: RestartLoginFormService,
    public clipboard: Clipboard,
    private activatedRoute: ActivatedRoute,
    private showFullRegisterFormService: ShowFullRegisterFormService
  ) {
    super(
      router,
      dialog,
      toastr,
      translationMWService,
      localStorageService,
      beInstallationsService,
      beDesktopService,
      restartLoginFormService,
      clipboard
    );
  }

  ngOnInit() {
    // get all URL parameters
    const language = this.activatedRoute.snapshot.paramMap.get('language');
    if (language) {
      this.translationMWService.setTransLanguage(language);
    }
    const encryptedReference = this.activatedRoute.snapshot.paramMap.get('reference');
    const encryptedDesktopKey = this.activatedRoute.snapshot.paramMap.get('desktopKey');
    this.registerOfflinePlusData = initializeClass(RegisterOfflinePlusEntity, {
      source: 'qr',
      encryptedDesktopKey,
      encryptedReference,
    });
    this.checkSum = this.activatedRoute.snapshot.paramMap.get('checkSum');

    if (this.validCheckSum) {
      this.registerPlusData = initializeClass(RegisterPlusActionTokenDataEntity, {
        ...this.registerOfflinePlusData,
      });
      this.beInstallationsService
        .checkInstallationReferenceInUse('qr', {
          encrypted: this.registerOfflinePlusData.encryptedReference,
        })
        .pipe(first())
        .subscribe((installation) => {
          this.referenceExists = true;
        });
    } else {
      this.toastr.error(
        this.translationMWService.instant('auth.register.registration-qr.toastr-error')
      );
      void this.router.navigate(['/auth/register-split/offline']);
    }
  }

  get validCheckSum(): boolean {
    return (
      this.checkSum &&
      this.registerOfflinePlusData &&
      this.registerOfflinePlusData.valid &&
      this.registerOfflinePlusData.matchesCheckSum(this.checkSum.toUpperCase())
    );
  }

  showNewCompanyRegistration() {
    this.registerEntryForm = this.newCompanyRegisterEntryForm;
    this.showFullRegisterFormService.showForm();
  }

  onNewCompanyFormCancelled() {
    this.registerEntryForm = this.loginRegisterEntryForm;
    this.showFullRegisterFormService.hideForm();
  }
}
