import { validate as isValidUUID } from 'uuid';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { initializeClass } from '@utils/utils';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { BEActionTokensService } from '@services/backend-services/action-tokens/be-action-tokens.service';
import { ActionTokenEntity } from '@entities/action-token.entity';
import { ActionTokenKindsConstants } from '@constants/action-tokens.constants';
import { ShowFullRegisterFormService } from '@modules/register/show-full-register-form.service';
import { DataProvidersConstants } from '@constants/data-providers.constants';
import {
  RequestDatumDialogComponent,
  RequestDatumDialogInjectedData,
  RequestDatumDialogReturnData,
} from '@shared/components/dialogs/request-datum-dialog/request-datum-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BEAuthService } from '@services/backend-services/auth/be-auth.service';
import { ILoginContext } from '@interfaces/login-context.interface';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { BEInstallationsService } from '@services/backend-services/installations/be-installations.service';
import { AuthLoginResponse } from '@entities/auth-login-response.entity';
import { errorLoginUser } from '@utils/users';
import { BaseRegisterComponent } from '../components/base-register.component';
import { BEDesktopService } from '@services/backend-services/desktop/be-desktop.service';
import { RegisterEntryForms } from '../components/classes/register-entry-forms';
import { RestartLoginFormService } from '@shared/components/login-form/restart-login-form.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-register-action-token',
  templateUrl: './register-action-token.component.html',
  styleUrls: ['./register-action-token.component.scss'],
})
export class RegisterActionTokenComponent extends BaseRegisterComponent implements OnInit {
  private actionToken: ActionTokenEntity;
  public knownEmail: string;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public translationMWService: TranslationMWService,
    public localStorageService: LocalStorageService,
    public beInstallationsService: BEInstallationsService,
    public beDesktopService: BEDesktopService,
    public restartLoginFormService: RestartLoginFormService,
    public clipboard: Clipboard,
    private activatedRoute: ActivatedRoute,
    private beAuthService: BEAuthService,
    private beActionTokensService: BEActionTokensService,
    private showFullRegisterFormService: ShowFullRegisterFormService
  ) {
    super(
      router,
      dialog,
      toastr,
      translationMWService,
      localStorageService,
      beInstallationsService,
      beDesktopService,
      restartLoginFormService,
      clipboard
    );
  }

  ngOnInit() {
    const actionTokenId = this.activatedRoute.snapshot.paramMap.get('id');
    if (actionTokenId && isValidUUID(actionTokenId)) {
      this.beActionTokensService
        .get(actionTokenId)
        .pipe(first())
        .subscribe(
          (actionToken) => {
            this.actionToken = actionToken;
            this.translationMWService.setTransLanguage(actionToken.language);
            /** REGISTERCOMPANY */
            if (
              Number(actionToken.kind) === ActionTokenKindsConstants.REGISTERCOMPANY.numberValue
            ) {
              if (actionToken.registerLiteData) {
                this.dataProvider = DataProvidersConstants.CTCLITE.numberValue;
                this.registerLiteData = actionToken.registerLiteData;
                if (actionToken.user) {
                  this.registerEntryForm = this.loginRegisterEntryForm;
                  this.showFullRegisterFormService.hideForm();
                  this.knownEmail = this.actionToken.user.email;
                } else {
                  this.registerEntryForm = this.newCompanyRegisterEntryForm;
                  this.showFullRegisterFormService.showForm();
                }
              }
              if (actionToken.registerPlusData) {
                this.dataProvider = DataProvidersConstants.CTCPLUS.numberValue;
                this.registerPlusData = actionToken.registerPlusData;
                this.registerPlusData.source = 'token';
                if (this.registerPlusData.valid) {
                  this.beInstallationsService
                    .checkInstallationReferenceInUse('qr', {
                      encrypted: this.registerPlusData.encryptedReference,
                    })
                    .pipe(first())
                    .subscribe((installation) => {
                      this.referenceExists = true;
                    });
                } else {
                  this.toastr.error(
                    this.translationMWService.instant('action.notfound.toastr.error.message'),
                    this.translationMWService.instant('action.notfound.toastr.error.title')
                  );
                  // navigate to offline registration page
                  void this.router.navigate(['/auth/register-split/offline']);
                }
              }
            } else {
              this.toastr.error(
                this.translationMWService.instant('action.only.registercompany.allowed.message'),
                this.translationMWService.instant('action.only.registercompany.allowed.title')
              );
              // navigate to offline registration page
              void this.router.navigate(['/auth/register-split/offline']);
            }
          },
          (error) => {
            if (error.status === HttpStatus.StatusCodes.NOT_FOUND) {
              this.toastr.error(
                this.translationMWService.instant('action.notfound.toastr.error.message'),
                this.translationMWService.instant('action.notfound.toastr.error.title')
              );
            }
            if (error.status === HttpStatus.StatusCodes.REQUEST_TIMEOUT) {
              const actionToken = initializeClass(
                ActionTokenEntity,
                error.error.data as Record<string, unknown>
              );
              // this.actionTokenExpired = true;
              this.translationMWService.setTransLanguage(actionToken.language).subscribe(() => {
                this.toastr.warning(
                  this.translationMWService.instant('action.expired.toastr.error.message'),
                  this.translationMWService.instant('action.expired.toastr.error.title')
                );
              });
            }
            // navigate to registration's default page
            void this.router.navigate(['/auth/register-split/offline']);
          }
        );
    } else {
      this.toastr.error(
        this.translationMWService.instant('action.notfound.toastr.error.message'),
        this.translationMWService.instant('action.notfound.toastr.error.title')
      );
      // navigate to registration's default page
      void this.router.navigate(['/auth/register-split/offline']);
    }
  }

  loopUntilLogin(): void {
    // open dialog requesting password
    const injectedData: RequestDatumDialogInjectedData = {
      title: this.translationMWService.instant('register.token-id.request-datum-dialog.title', {
        email: this.actionToken.user.email,
      }),
      inputLabel: 'register.token-id.request-datum-dialog.datum-label',
      confirmBtnLabel: 'register.token-id.request-datum-dialog.confirm-btn',
      isAPassword: true,
    };
    this.dialog
      .open(RequestDatumDialogComponent, { data: injectedData })
      .afterClosed()
      .subscribe((returnData: RequestDatumDialogReturnData) => {
        if (returnData.answer) {
          const loginContext: ILoginContext = {
            email: this.actionToken.user.email,
            password: returnData.outputValue,
          };
          this.beAuthService
            .login(loginContext)
            .pipe(first())
            .subscribe(
              (response: AuthLoginResponse) => {
                this.doAfterLogin(response);
              },
              (error) => {
                errorLoginUser(error, this.toastr, this.translationMWService);

                this.loopUntilLogin();
              }
            );
        } else {
          this.loopUntilLogin();
        }
      });
  }

  showNewCompanyRegistration() {
    this.registerEntryForm = this.newCompanyRegisterEntryForm;
    this.showFullRegisterFormService.showForm();
  }

  onNewCompanyFormCancelled() {
    this.registerEntryForm = this.loginRegisterEntryForm;
    this.showFullRegisterFormService.hideForm();
  }
}
