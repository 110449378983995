<div class="login-form-container">
  <form *ngIf="!loading" [formGroup]="loginForm" #f="ngForm" (ngSubmit)="loginUser()">
    <div class="inputs">
      <div class="mat-form-field-container">
        <span class="fake-label m3-label-large">{{ 'login.form.email' | translate }}</span>
        <mat-form-field class="input-full-width">
          <mat-label>{{ 'auth.login.emailPlaceholder' | translate }}</mat-label>
          <input
            matInput
            formControlName="email"
            trim="blur"
            type="text"
            placeholder="{{ 'auth.login.emailPlaceholder' | translate }}"
            appToLowercase
            [readonly]="knownEmail !== undefined"
          />
          <mat-error *ngIf="loginForm.controls.email.hasError('required')">
            {{ 'form.error.fieldRequired' | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              loginForm.controls.email.hasError('email') ||
              loginForm.controls.email.hasError('pattern')
            "
          >
            {{ 'form.error.typeEmail' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="mat-form-field-container">
        <span class="fake-label m3-label-large">{{ 'login.form.password' | translate }}</span>
        <mat-form-field class="input-full-width">
          <mat-label>{{ 'login.form.placeholders.password' | translate }}</mat-label>
          <input
            matInput
            formControlName="password"
            name="password"
            autocomplete="on"
            [type]="hidePassword ? 'password' : 'text'"
            placeholder="{{ 'login.form.placeholders.password' | translate }}"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="$event.stopPropagation(); hidePassword = !hidePassword"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword"
          >
            <span class="material-symbols-outlined">
              {{ hidePassword ? 'visibility_off' : 'visibility' }}
            </span>
          </button>
          <mat-error *ngIf="loginForm.controls.password.hasError('required')">
            {{ 'form.error.fieldRequired' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="helpers d-flex justify-content-between">
      <div
        class="helper-link m3-label-small cursor-pointer"
        [routerLink]="['/auth/forgot-password']"
      >
        {{ 'login.forgotPassword' | translate }}
      </div>
    </div>
    <div class="buttons">
      <button
        type="submit"
        mat-flat-button
        color="primary"
        class="button-primary font-button"
        [disabled]="!loginForm.pristine && !loginForm.valid"
      >
        <span class="material-symbols-outlined icon"> login </span>
        <span class="btn-text">{{ 'auth.button.login' | translate }}</span>
      </button>
    </div>
  </form>
  <div *ngIf="loading" class="custom-bar">
    <app-custom-progressbar
      [progressValue]="progressValue"
      [minValue]="0"
      [maxValue]="100"
      [showStatus]="false"
      [loadingTitle]="'auth.login.loadingTitle' | translate"
    ></app-custom-progressbar>
  </div>
</div>
