import * as HttpStatus from 'http-status-codes';
import { Component } from '@angular/core';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { BaseComponent } from '@shared/components/base.component';
import {
  RegisterLiteActionTokenDataEntity,
  RegisterPlusActionTokenDataEntity,
} from '@entities/action-token-data.entity';
import { LoginDialogComponent } from '@shared/components/dialogs/login-dialog/login-dialog.component';
import { AuthLoginResponse } from '@entities/auth-login-response.entity';
import { BEInstallationsService } from '@services/backend-services/installations/be-installations.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { InstallationFromTokenOrQREntity } from '@entities/installation.entity';
import { toastEncryptedReference, initializeClass } from '@utils/utils';
import { errorSavingInstallation } from '@utils/errors';
import { DataProvidersConstants } from '@constants/data-providers.constants';
import { BEDesktopService } from '@services/backend-services/desktop/be-desktop.service';
import { RegisterEntryForms } from './classes/register-entry-forms';
import { RestartLoginFormService } from '@shared/components/login-form/restart-login-form.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({ template: '' })
export class BaseRegisterComponent extends BaseComponent {
  public dataProvider: number;
  public registerLiteData: RegisterLiteActionTokenDataEntity;
  public registerPlusData: RegisterPlusActionTokenDataEntity;
  public loginRegisterEntryForm = RegisterEntryForms.LOGIN;
  public newCompanyRegisterEntryForm = RegisterEntryForms.NEW_COMPANY;
  public registerEntryForm = RegisterEntryForms.LOGIN;
  public referenceExists = false;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public translationMWService: TranslationMWService,
    public localStorageService: LocalStorageService,
    public beInstallationsService: BEInstallationsService,
    public beDesktopService: BEDesktopService,
    public restartLoginFormService: RestartLoginFormService,
    public clipboard: Clipboard
  ) {
    super(localStorageService);
  }

  offerToLogin(): void {
    this.dialog
      .open(LoginDialogComponent)
      .afterClosed()
      .subscribe((response: AuthLoginResponse) => {
        if (response) {
          this.doAfterLogin(response);
        }
      });
  }

  public doAfterLogin(response: AuthLoginResponse): void {
    this.localStorageService.feedFromLoginResponse(response);
    // set user's language preference
    this.translationMWService.setTransLanguage(response.language);

    if (this.registerLiteData) {
      // claim Lite installation
      this.beInstallationsService
        .claimInstallationByRegistrationCode(this.registerLiteData.registrationCode)
        .pipe(first())
        .subscribe(
          (installation) => {
            this.toastr.success(
              this.translationMWService.instant('installation.claim.toastr.success.message', {
                installation: installation.name,
              }),
              this.translationMWService.instant('installation.claim.toastr.success.title')
            );
            // move to lite installation
            void this.router.navigate([`/installations/of-my-company/ctclite/${installation.id}`]);
          },
          (error: HttpErrorResponse) => {
            let errorMessageLabel = 'installation.claim.toastr.error.';

            if (error.status === HttpStatus.StatusCodes.NOT_FOUND) {
              errorMessageLabel += 'not-found';
            }
            if (error.status === HttpStatus.StatusCodes.PRECONDITION_FAILED) {
              errorMessageLabel += 'not-claimable';
            }
            if (error.status === HttpStatus.StatusCodes.UNAUTHORIZED) {
              errorMessageLabel += 'not-authorized';
            }
            if (!errorMessageLabel.endsWith('.')) {
              this.toastr.error(this.translationMWService.instant(errorMessageLabel));
            }
            // log user out
            this.localStorageService.clear();
            // restart login form
            this.restartLoginFormService.restartOn();
          }
        );
    }
    if (this.registerPlusData && this.registerPlusData.valid) {
      const payload: InstallationFromTokenOrQREntity = initializeClass(
        InstallationFromTokenOrQREntity,
        {
          dataProvider: DataProvidersConstants.CTCPLUS.numberValue,
          temperatureUoM: 'C',
          sensorAlarms: [],
          name: this.registerPlusData.reference,
          offlineDesktopLink: this.registerPlusData,
        }
      );
      if (!this.referenceExists) {
        // create new installation with provided reference
        this.beInstallationsService
          .createFromTokenOrQR(payload)
          .pipe(first())
          .subscribe(
            (installation) => {
              this.toastr.success(
                this.translationMWService.instant(`installation.form.create.toastr.success.message`)
              );
              if (this.registerPlusData && this.registerPlusData.validQR) {
                toastEncryptedReference(
                  this.beDesktopService,
                  this.clipboard,
                  this.registerPlusData.reference,
                  this.toastr,
                  this.translationMWService
                );
              }
              // move to plus installation
              void this.router.navigate([
                `/installations/of-my-company/ctcplus/${installation.id}`,
              ]);
            },
            (error) => {
              errorSavingInstallation(error, this.toastr, this.translationMWService);
              // log user out
              this.localStorageService.clear();
              // restart login form
              this.restartLoginFormService.restartOn();
            }
          );
      } else {
        // update desktop link to installation with provided reference
        this.beInstallationsService
          .updateFromTokenOrQR(payload)
          .pipe(first())
          .subscribe(
            (installation) => {
              this.toastr.success(
                this.translationMWService.instant(`installation.form.relink.toastr.success.message`)
              );
              if (this.registerPlusData && this.registerPlusData.validQR) {
                toastEncryptedReference(
                  this.beDesktopService,
                  this.clipboard,
                  this.registerPlusData.reference,
                  this.toastr,
                  this.translationMWService
                );
              }
              // move to plus installation
              void this.router.navigate([
                `/installations/of-my-company/ctcplus/${installation.id}`,
              ]);
            },
            (error) => {
              if (error.status === HttpStatus.StatusCodes.CONFLICT) {
                this.toastr.error(
                  this.translationMWService.instant('installation.form.relink.toastr.error.message')
                );
              } else if (error.status === HttpStatus.StatusCodes.PRECONDITION_FAILED) {
                this.toastr.error(
                  this.translationMWService.instant(
                    'register.toast.duplicated-installation-reference-found.message'
                  )
                );
              } else {
                errorSavingInstallation(error, this.toastr, this.translationMWService);
              }
              // log user out
              this.localStorageService.clear();
              // restart login form
              this.restartLoginFormService.restartOn();
            }
          );
      }
    }
  }
}
