import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { TranslationMWService } from '@services/translation-middleware/translation-middleware.service';
import { ShowFullRegisterFormService } from '@modules/register/show-full-register-form.service';
import { DataProvidersConstants } from '@constants/data-providers.constants';
import { BEInstallationsService } from '@services/backend-services/installations/be-installations.service';
import { envFileToObject, handleFileInput, initializeClass } from '@utils/utils';
import { Router } from '@angular/router';
import { RegisterLiteActionTokenDataEntity } from '@entities/action-token-data.entity';
import { BaseRegisterComponent } from '../components/base-register.component';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { BEDesktopService } from '@services/backend-services/desktop/be-desktop.service';
import { RegisterOfflinePlusEntity } from '@entities/register-offline-plus.entity';
import { RestartLoginFormService } from '@shared/components/login-form/restart-login-form.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-register-offline-plus',
  templateUrl: './register-offline-plus.component.html',
  styleUrls: ['./register-offline-plus.component.scss'],
})
export class RegisterOfflinePlusComponent extends BaseRegisterComponent {
  public dataProvider: number = DataProvidersConstants.CTCPLUS.numberValue;
  public liteRegistrationCode: string;
  public knownCompanyName: string;

  public registrationCodeForm: FormGroup = new FormGroup({
    code: new FormControl('', [Validators.required]),
  });

  public loading = false;
  public progressValue = 6;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public translationMWService: TranslationMWService,
    public localStorageService: LocalStorageService,
    public beInstallationsService: BEInstallationsService,
    public beDesktopService: BEDesktopService,
    public restartLoginFormService: RestartLoginFormService,
    public clipboard: Clipboard,
    private showFullRegisterFormService: ShowFullRegisterFormService
  ) {
    super(
      router,
      dialog,
      toastr,
      translationMWService,
      localStorageService,
      beInstallationsService,
      beDesktopService,
      restartLoginFormService,
      clipboard
    );
  }

  validateRegistrationCode() {
    this.beInstallationsService
      .checkClaimableInstallation(this.registrationCodeForm.value.code)
      .pipe(first())
      .subscribe(
        (installation) => {
          this.knownCompanyName = installation.name;
          this.liteRegistrationCode = installation.registrationCode;
          this.dataProvider = DataProvidersConstants.CTCLITE.numberValue;
          this.registerLiteData = initializeClass(RegisterLiteActionTokenDataEntity, {
            registrationCode: installation.registrationCode,
          });
          this.showFullRegisterFormService.showForm();
        },
        (checkClaimableInstallationError) => {
          if (checkClaimableInstallationError.status === HttpStatus.StatusCodes.NOT_FOUND) {
            this.toastr.error(
              this.translationMWService.instant('registration-code.invalid.toastr.error.message')
            );
          }
          if (
            checkClaimableInstallationError.status === HttpStatus.StatusCodes.PRECONDITION_FAILED
          ) {
            this.toastr.error(
              this.translationMWService.instant(
                'lite-registration-code.validation.toastr.error.message'
              )
            );
          }
        }
      );
  }

  public handleTXTFileInput(file: FileList) {
    const onLoadCallback = (fileToUpload: File) => (event: any) => {
      const txtContents: string = event.target.result;
      const rawObject = envFileToObject(txtContents);
      const registerOfflinePlusData: RegisterOfflinePlusEntity = initializeClass(
        RegisterOfflinePlusEntity,
        {
          encryptedDesktopKey: rawObject.desktopKey,
          encryptedReference: rawObject.reference,
          checkSum: rawObject.checkSum,
        }
      );
      const languagePrm = rawObject.language ? `/${rawObject.language}` : '';
      void this.router.navigate([
        `/auth/register-split/qr/${registerOfflinePlusData.encryptedReference}/${registerOfflinePlusData.encryptedDesktopKey}/${registerOfflinePlusData.checkSum}${languagePrm}`,
      ]);
    };

    handleFileInput(file, 'txt', 'TEXT', this.toastr, this.translationMWService, onLoadCallback);
  }

  onNewCompanyFormCancelled() {
    this.showFullRegisterFormService.hideForm();
    // navigate to login page
    void this.router.navigate(['/auth/login']);
  }
}
